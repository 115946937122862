import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Fyndorich Fields
			</title>
			<meta name={"description"} content={"Her maçın ev sahibi gibi hissedildiği yer"} />
			<meta property={"og:title"} content={"Fyndorich"} />
			<meta property={"og:description"} content={"Her maçın ev sahibi gibi hissedildiği yer"} />
			<meta property={"og:image"} content={"https://fyndorich.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://fyndorich.com/img/5043560.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://fyndorich.com/img/5043560.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://fyndorich.com/img/5043560.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://fyndorich.com/img/5043560.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://fyndorich.com/img/5043560.png"} />
			<meta name={"msapplication-TileImage"} content={"https://fyndorich.com/img/5043560.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 150px 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline1"
					color="--dark"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Kişiler
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--dark" text-align="center">
					Yerinizi ayırtmak veya hizmetlerimiz hakkında bilgi almak için bizimle iletişime geçin. Ekibimiz, oyununuzun aksamadan oynanmasını sağlamak için burada.
				</Text>
				<Link
					href="mailto:contact-us@fyndorich.com"
					text-align="center"
					color="--dark"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					contact-us@fyndorich.com
				</Link>
				<Link
					href="tel:+90 224 2356161"
					text-align="center"
					color="--dark"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					+90 224 2356161
				</Link>
				<Text margin="0px 0px 48px 0px" font="--headline3" color="--dark" text-align="center">
					Işıktepe, Ormanlar Cd. No:5, 16140 Işıktepe Osb/Nilüfer/Bursa, Türkiye
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});